import * as React from "react"
import styled from "styled-components";
import theme from "../../config/theme";


const CrossIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const CrossIcon = ({color, width, height, ...props}) => (
    <CrossIconWrapper>
        <svg
            viewBox="0 0 32 32"
            width={width || 16}
            height={height || 16}
            {...props}
        >
            <path
                stroke={color || theme.colors.grey}
                d="m7 7 18 18M7 25 25 7"
            />
        </svg>
    </CrossIconWrapper>
)

export default CrossIcon
