import * as React from "react"

const Statistics = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 20 20"
        width={width || 20}
        height={height || 20}
        fill="none"
        {...props}
    >
        <path
            d="M1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-6Zm7-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V8Zm7-6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Statistics
