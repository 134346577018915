import {createSlice} from "@reduxjs/toolkit";

const dialogSlice = createSlice({
    name: "dialog",

    initialState: {
        dialogs: {}
    },

    reducers: {
        openDialog(state, paramsObj) {
            const dialogData = paramsObj.payload;
            const dialogId = dialogData.id;
            const dialogProps = dialogData.props;
            state.dialogs[dialogId] = {};
            state.dialogs[dialogId].open = true;
            state.dialogs[dialogId].props = dialogProps;
        },

        closeDialog(state, paramsObj) {
            const dialogId = paramsObj.payload;
            if (state.dialogs[dialogId]) {
                state.dialogs[dialogId].open = false;
            }
        },

        closeAllDialogs(state) {
            Object.keys(state.dialogs).forEach(dialogId => {
                state.dialogs[dialogId].open = false;
            });
        }
    }
});

export default dialogSlice.reducer;

export const {
    openDialog,
    closeDialog,
    closeAllDialogs
} = dialogSlice.actions;