import React from 'react';
import styled from "styled-components";
import theme from "../../../config/theme";
import Workspace from "../../../assets/icons/menu/Workspace";

const SideMenuHeaderWrapper = styled.div`
  display: flex;
  margin: 10px 0;
  padding-left: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleTextWrapper = styled.div`
  color: black;
  font-weight: ${theme.sizes.fontWeight.bold};
  word-break: keep-all;
`;

const CustomIconWrapper = styled.div`
  margin-right: 15px;
  border-radius: 50%;
`;

const SideMenuHeader = (props) => {
    const {
        headerTitle
    } = props;

    return (
        <SideMenuHeaderWrapper>
            <TitleWrapper>
                <CustomIconWrapper>
                    <Workspace/>
                </CustomIconWrapper>

                <TitleTextWrapper>
                    {headerTitle}
                </TitleTextWrapper>
            </TitleWrapper>
        </SideMenuHeaderWrapper>
    );
};

export default SideMenuHeader;