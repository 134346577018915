import React from 'react';
import styled from "styled-components";
import theme from "../../../config/theme";
import PointsIcon
    from "../../../assets/icons/PointsIcon";
import Tooltip from "../../UI/Tooltip";
import MoreIcon from '../../../assets/icons/MoreIcon'
import PlusIcon from "../../../assets/icons/PlusIcon";


const ColumnHeaderWrapper = styled.div`
  height: 30px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  background: ${theme.colors.elements.backgroundColor};
  border-radius: 0 0 4px 4px;
`;

const HeaderTitle = styled.div`
  flex-grow: 1;
  margin: 0 15px;
  font-weight: ${theme.sizes.fontWeight.bold};
`;

const TooltippedIconWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 18px;
  }
`;

const ColumnHeader = ({title}) => {
    return (
        <ColumnHeaderWrapper>
            <PointsIcon/>
            <HeaderTitle>
                {title}
            </HeaderTitle>

            <TooltippedIconWrapper>
                <Tooltip text='Подробности'>
                    <MoreIcon/>
                </Tooltip>
            </TooltippedIconWrapper>
            <TooltippedIconWrapper>
                <Tooltip text='Добавить'>
                    <PlusIcon/>
                </Tooltip>
            </TooltippedIconWrapper>
        </ColumnHeaderWrapper>
    );
};

export default ColumnHeader;