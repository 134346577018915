import React from 'react';

const Tasks = () => {
    return (
        <div>
            <h1>TASKS PAGE</h1>
        </div>
    );
};

export default Tasks;