import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import DialogHeader from "./DialogHeader";
import theme from "../../../config/theme";
import {createPortal} from "react-dom";

const DialogOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${theme.colors.elements.dialog.overlay};
  z-index: 0;
`;

const DialogWrapper = styled.div`
  display: ${props => props.open ? "flex" : "none"};
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  min-width: 600px;
  max-width: 800px;
  padding: 15px;
  background: white;
  border-radius: 10px;
  z-index: 100;
`;

const Dialog = (props) => {
    const {
        open,
        title,
        children,
        onClose
    } = props;

    const overlayRef = useRef(null);

    useEffect(() => {
        window.addEventListener("keydown", keyDownListener);

        return () => {
            window.removeEventListener("keydown", keyDownListener);
        }
    }, []);

    function keyDownListener(event) {
        if (event.key === "Escape") {
            onClose();
        }
    }

    function overlayClick(event) {
        if (event.target === overlayRef.current) {
            onClose();
        }
    }

    return createPortal(
        <DialogOverlay
            ref={overlayRef}
            onClick={overlayClick}
        >
            <DialogWrapper
                open={open}
            >
                <DialogHeader onClose={onClose}>
                    {title}
                </DialogHeader>

                {children}
            </DialogWrapper>
        </DialogOverlay>,
        document.getElementById("root")
    );
}

export default Dialog;