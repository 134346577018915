import React from 'react';
import ReactDOM from 'react-dom';
import {createGlobalStyle} from "styled-components";
import {Provider} from "react-redux";
import App from './App';
import {store} from "./store";
import theme from "./config/theme";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Axiforma', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    word-break: break-word;
  }

  *::selection {
    background-color: ${theme.colors.selection.backgroundColor};
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.blue};
    border-radius: ${theme.sizes.borderRadius.default};
  }

  .masonry-grid {
    display: flex;
    width: 100%;
    margin-right: ${theme.sizes.projectsPage.column.margin};
  }

  .masonry-grid_column {
    &:not(:last-child) {
      margin-right: ${theme.sizes.projectsPage.column.margin};
    }
  }
`;

ReactDOM.render(
    <Provider store={store}>
        <GlobalStyles/>
        <App/>
    </Provider>,
    document.getElementById('root')
);