import {createSlice} from "@reduxjs/toolkit";
import {
    PROJECTS_COLUMNS_DEFAULT,
    PROJECTS_COLUMNS_ORDER_DEFAULT
} from "../../config/consts";

const columnsStorage = JSON.parse(localStorage.getItem("columns"));
const columns = columnsStorage ? columnsStorage : PROJECTS_COLUMNS_DEFAULT;

const columnsOrderStorage = JSON.parse(localStorage.getItem("columnsOrder"));
const columnsOrder = columnsOrderStorage ? columnsOrderStorage : PROJECTS_COLUMNS_ORDER_DEFAULT;

const projectsColumnsSlice = createSlice({
    name: "projectColumns",

    initialState: {
        columns,
        columnsOrder
    },

    reducers: {
        movePlate(state, paramsObj) {
            const source = paramsObj.payload.source;
            const destination = paramsObj.payload.destination;
            const draggableId = paramsObj.payload.draggableId;

            const start = state.columns[source.droppableId];
            const finish = state.columns[destination.droppableId];

            if (start === finish) {
                const newPlatesIds = Array.from(start.platesIds);

                newPlatesIds.splice(source.index, 1);
                newPlatesIds.splice(destination.index, 0, draggableId);

                state.columns[start.id].platesIds = newPlatesIds;
            } else {
                const startPlatesIds = Array.from(start.platesIds);
                startPlatesIds.splice(source.index, 1);

                const finishPlatesIds = Array.from(finish.platesIds);
                finishPlatesIds.splice(destination.index, 0, draggableId);

                state.columns[start.id].platesIds = startPlatesIds;
                state.columns[finish.id].platesIds = finishPlatesIds;
            }

            localStorage.setItem("columns", JSON.stringify(state.columns));
        },

        addPlateToUnassignedColumn(state, paramsObj) {
            const plateId = paramsObj.payload.plateId;

            state.columns["column-0"].platesIds.push(plateId);
            localStorage.setItem("columns", JSON.stringify(state.columns));
        },

        removePlateFromColumn(state, paramsObj) {
            const columnId = paramsObj.payload.columnId;
            const plateId = paramsObj.payload.plateId;

            const plateIndex = state.columns[columnId].platesIds.findIndex(
                element => element === plateId
            );
            state.columns[columnId].platesIds.splice(plateIndex, 1);

            localStorage.setItem("columns", JSON.stringify(state.columns));
        },

        addPlatesListToUnassignedColumn(state, paramsObj) {
            const plates = paramsObj.payload.plates;

            plates.forEach(plate => {
                const plateId = plate.id;
                state.columns["column-0"].platesIds.push(plateId);
            });

            localStorage.setItem("columns", JSON.stringify(state.columns));
        },

        addColumn(state, paramsObj) {
            const columnTitle = paramsObj.payload.columnTitle;
            const columnColor = paramsObj.payload.columnColor;
            const columnId = "column-" + Date.now();

            state.columns[columnId] = {
                id: columnId,
                title: columnTitle,
                color: columnColor,
                platesIds: []
            }
            state.columnsOrder.push(columnId);

            localStorage.setItem("columns", JSON.stringify(state.columns));
            localStorage.setItem(
                "columnsOrder",
                JSON.stringify(state.columnsOrder)
            );
        },

        moveColumn(state, paramsObj) {
            const activeIndex = paramsObj.payload.activeIndex;
            const overIndex = paramsObj.payload.overIndex;

            const activeId = paramsObj.payload.activeId;
            const overId = paramsObj.payload.overId;

            const newColumnsOrder = Array.from(state.columnsOrder);
            newColumnsOrder.splice(activeIndex, 1, overId);
            newColumnsOrder.splice(overIndex, 1, activeId);

            state.columnsOrder = newColumnsOrder;

            localStorage.setItem(
                "columnsOrder",
                JSON.stringify(state.columnsOrder)
            );
        },

        editColumn(state, paramsObj) {
            const columnTitle = paramsObj.payload.columnTitle;
            const columnId = paramsObj.payload.columnId;
            const color = paramsObj.payload.color

            state.columns[columnId].title = columnTitle;
            state.columns[columnId].color = color;

            localStorage.setItem("columns", JSON.stringify(state.columns));
        },

        deleteColumn(state, paramsObj) {
            const columnId = paramsObj.payload.columnId;

            const orderIdx = state.columnsOrder.indexOf(columnId)
            state.columnsOrder.splice(orderIdx, 1)

            delete state.columns[columnId]

            localStorage.setItem("columns", JSON.stringify(state.columns));
            localStorage.setItem(
                "columnsOrder",
                JSON.stringify(state.columnsOrder)
            );
        }
    }
});

export default projectsColumnsSlice.reducer;

export const {
    movePlate,
    addPlateToUnassignedColumn,
    removePlateFromColumn,
    addPlatesListToUnassignedColumn,
    addColumn,
    moveColumn,
    editColumn,
    deleteColumn
} = projectsColumnsSlice.actions;