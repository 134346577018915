import axios from "../middleware/axios";
import getUrlFromPath from "../utils/getUrlFromPath";
import {authPaths} from "../config/paths";

export function getToken(state) {
    return axios({
        method: "GET",
        url: getUrlFromPath(authPaths.TOKEN),
        params: {
            state
        }
    });
}