import React from 'react';
import styled from "styled-components";
import {Droppable} from "react-beautiful-dnd";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import theme from "../../config/theme";
import ColumnHeader from "./ColumnHeader";
import ProjectsPlate from "../ProjectsPlate";
import UnassignedColumnHeader from "./UnassignedColumnHeader";

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-bottom: ${theme.sizes.projectsPage.column.margin};
  padding: 8px;
  background: linear-gradient(45deg,
  ${theme.colors.elements.projectsColumn.backgroundColors.blue},
  ${theme.colors.elements.projectsColumn.backgroundColors.violet});
  border-radius: 10px;
  color: ${theme.colors.font.dark};

  @media (orientation: portrait) {
    width: 100%;
    min-width: auto;
    height: auto;
    min-height: auto;
  }
`;

const HeaderWrapper = styled.div`
  cursor: ${props => props.cursor};
`;

const ColumnBody = styled.div`
  min-height: 50px;
  flex-grow: 1;
`;

const ProjectsColumn = (props) => {
    const {
        plates,
        column,
        columnType,
        draggable,
        editIconClick,
        deleteIconClick,
        unassignedColumnInputChange
    } = props;

    let {
        setNodeRef,
        attributes,
        listeners,
        transition,
        transform
    } = useSortable({id: column.id})

    if (transform) {
        transform.scaleX = 1;
        transform.scaleY = 1;
    }
    let style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    if (!draggable) {
        setNodeRef = null;
        style = null;
        attributes = null;
        style = null;
    }

    return (
        <ColumnWrapper
            ref={setNodeRef}
            style={style}
        >
            <HeaderWrapper
                {...attributes}
                {...listeners}
                cursor={columnType === "default" ? "move" : "default"}
            >
                <Header
                    type={columnType}
                    title={column.title}
                    topLineColor={theme.colors[column.color]}
                    editIconClick={() => editIconClick(
                        column.id, column.title, column.color
                    )}
                    deleteIconClick={() => deleteIconClick(
                        column.id, column.title
                    )}
                    inputChange={unassignedColumnInputChange}
                />
            </HeaderWrapper>

            <Droppable droppableId={column.id}>
                {(provided) => (
                    <ColumnBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        <PlatesList
                            plates={plates}
                            columnId={column.id}
                            columnColor={column.color}
                        />
                        {provided.placeholder}
                    </ColumnBody>
                )}
            </Droppable>
        </ColumnWrapper>
    );
}

const Header = React.memo(function Header(props) {
    const {
        type,
        title,
        topLineColor,
        editIconClick,
        deleteIconClick,
        inputChange
    } = props;

    return (
        <>
            {type === "default" &&
                <ColumnHeader
                    title={title}
                    topLineColor={topLineColor}
                    editIconClick={editIconClick}
                    deleteIconClick={deleteIconClick}
                />
            }

            {type === "unassigned" &&
                <UnassignedColumnHeader
                    title={title}
                    topLineColor={topLineColor}
                    editIconClick={editIconClick}
                    inputChange={inputChange}
                />
            }
        </>
    );
});

const PlatesList = React.memo(function PlatesList(props) {
    const {
        plates,
        columnId,
        columnColor
    } = props;

    return (
        <>
            {plates.map((plate, index) =>
                <ProjectsPlate
                    key={plate.id}
                    plateBodyData={plate}
                    index={index}
                    columnId={columnId}
                    columnColor={columnColor}
                />
            )}
        </>
    );
}, function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});

export default ProjectsColumn;