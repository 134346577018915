import {createSlice} from "@reduxjs/toolkit";
import {PROJECTS_PLATES_DEFAULT} from "../../config/consts";

const platesStorage = JSON.parse(localStorage.getItem("plates"));
const plates = platesStorage ? platesStorage : PROJECTS_PLATES_DEFAULT;

const projectsPlatesSlice = createSlice({
    name: "projectPlates",

    initialState: {
        plates
    },

    reducers: {
        addPlate(state, paramsObj) {
            const employeeName = paramsObj.payload.employeeName;
            const plateId = paramsObj.payload.plateId;

            state.plates[plateId] = {
                id: plateId,
                name: {
                    key: "Имя: ",
                    value: employeeName
                },
            }

            localStorage.setItem("plates", JSON.stringify(state.plates));
        },

        removePlate(state, paramsObj) {
            const plateId = paramsObj.payload.plateId;

            delete state.plates[plateId];

            localStorage.setItem("plates", JSON.stringify(state.plates));
        },

        addPlatesList(state, paramsObj) {
            const plates = paramsObj.payload.plates;

            plates.forEach(plate => {
                const plateId = plate.id;
                const firstName = plate.employee_name_first_name;
                const lastName = plate.employee_name_last_name;

                state.plates[plateId] = {
                    id: plateId,
                    name: {
                        key: "Имя: ",
                        value: firstName + " " + lastName
                    }
                }
            });

            localStorage.setItem("plates", JSON.stringify(state.plates));
        },

        filterPlates(state, paramsObj) {
            const searchText = paramsObj.payload.searchText;
            const platesIds = paramsObj.payload.platesIds;

            if (searchText === "") {
                for (let key in state.plates) {
                    state.plates[key].visible = true;
                }
                return;
            }
            
            const regexp = new RegExp(searchText, "i");
            for (let i = 0; i < platesIds.length; i++) {
                const plateId = platesIds[i];
                state.plates[plateId].visible = regexp.test(
                    state.plates[plateId].name.value
                );
            }
        },

        searchPlates(state, paramsObj) {
            const searchText = paramsObj.payload;
            
            if (searchText === '') {
                for (let key in state.plates) {
                    state.plates[key].searched = false;
                }
                return;
            }

            const regexp = new RegExp(searchText, 'i');
            for (let key in state.plates) {
                state.plates[key].searched = regexp.test(
                    state.plates[key].name.value
                );
            }
        }
    }
});

export default projectsPlatesSlice.reducer;
export const {
    addPlate,
    removePlate,
    searchPlates,
    addPlatesList,
    filterPlates
} = projectsPlatesSlice.actions;