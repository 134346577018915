import React from 'react';
import Input from "../../components/UI/Input";
import theme from "../../config/theme";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {DragDropContext} from "react-beautiful-dnd";
import WorkloadColumn from "../../components/WorkloadColumn";

const WorkloadPageWrapper = styled.div`
  min-width: 100%;
  height: 100%;
`;

const InputWrapper = styled.div`
  width: 250px;
  margin-bottom: 10px;
`;

const ContentWrapper = styled.div`
  width: 3250px;
  display: flex;
  overflow: auto;
`;

const Workload = () => {
    const columns = useSelector(state => state.workload.columnsSlice.columns);
    const columnsOrder = useSelector(state =>
        state.workload.columnsSlice.columnsOrder);
    const plates = useSelector(state => state.workload.platesSlice.plates);

    const onDragEnd = ({source, destination, draggableId}) => {
        if (!destination) return;

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            //
        }
    }

    return (
        <WorkloadPageWrapper>
            <InputWrapper>
                <Input
                    // value={valLogin}
                    // onChange={(value) => setValLogin(value)}
                    placeholder='Поиск'
                />
            </InputWrapper>

            <ContentWrapper>
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    {columnsOrder.map(columnId => {
                        const column = columns[columnId]
                        const tasks = column.platesIds.map(taskId => plates[taskId]);
                        return (
                            <WorkloadColumn
                                key={column.id}
                                column={column}
                                tasks={tasks}
                                title={column.title}
                            />
                        );
                    })}
                </DragDropContext>
            </ContentWrapper>
        </WorkloadPageWrapper>
    );
};

export default Workload;