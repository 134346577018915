import * as React from "react"
import theme from "../../config/theme";
import styled from "styled-components";


const EditIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const EditIcon = ({color, width, height, ...props}) => (
        <EditIconWrapper>
            <svg
                viewBox="0 0 32 32"
                width={width || 15}
                height={height || 15}
                fill="none"
                {...props}
            >
                <path
                    fill={color || theme.colors.grey}
                    d="M2 29a1 1 0 0 1-1-1.11l.77-7a1 1 0 0 1 .29-.59L18.42 3.94a3.2 3.2 0 0 1 4.53 0l3.11 3.11a3.2 3.2 0 0 1 0 4.53L9.71 27.93a1 1 0 0 1-.59.29l-7 .77Zm7-1.78Zm-5.27-5.77-.6 5.42 5.42-.6 16.1-16.1a1.2 1.2 0 0 0 0-1.7l-3.12-3.12a1.2 1.2 0 0 0-1.7 0Z"
                />
                <path
                    fill={color || theme.colors.grey}
                    d="M23 14.21a1 1 0 0 1-.71-.29l-6.21-6.23a1 1 0 0 1 1.42-1.42l6.23 6.23a1 1 0 0 1 0 1.42 1 1 0 0 1-.73.29Z"
                />
            </svg>
        </EditIconWrapper>
)

export default EditIcon