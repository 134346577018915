import React, {useState} from 'react';
import theme from "../../../config/theme";
import styled from "styled-components";

const InputWrapper = styled.input`
  width: ${props => props.fullWidth ? "100%" : "auto"};
  height: 30px;
  background: none;
  outline: none;
  border: none;
  border-bottom: 2px solid ${props => {
    return props.error ? theme.colors.red : props.active
            ? theme.colors.blue : theme.colors.font.dark
  }};
  transition: border 0.25s ease;

  &::placeholder {
    transition: color 0.25s ease;
    color: ${props => {
      return props.error ? theme.colors.red : props.active
              ? theme.colors.blue : theme.colors.font.dark
    }};
  }
`;

const Input = (props) => {
    const {
        fullWidth,
        autoFocus,
        value,
        type,
        placeholder,
        error,
        onChange,
        onKeyDown
    } = props;

    const [active, setActive] = useState(false);

    return (
        <div>
            <InputWrapper
                autoFocus={autoFocus}
                fullWidth={fullWidth}
                value={value}
                type={type || "text"}
                placeholder={placeholder}
                error={error}
                active={active}
                onFocus={() => setActive(true)}
                onBlur={() => setActive(false)}
                onChange={(event) => onChange(event.target.value)}
                onKeyDown={onKeyDown}
            />
        </div>
    );
};

export default Input;