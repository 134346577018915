import React from 'react';
import theme from "../../../config/theme";
import styled from "styled-components";

const ChipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  background: ${props => props.backgroundColor || theme.colors.blue};
  color: ${props => props.textColor || theme.colors.font.light};
  border-radius: ${theme.sizes.borderRadius.default};
  cursor: pointer;
`;

const LabelWrapper = styled.div`
  margin-right: 8px;
`;

const Chip = (props) => {
    const {
        id,
        backgroundColor,
        textColor,
        icon,
        label,
        onClick
    } = props;

    const IconComponent = icon;

    return (
        <ChipWrapper
            backgroundColor={backgroundColor}
            textColor={textColor}
            onClick={() => onClick(id)}
        >
            <LabelWrapper>
                {label}
            </LabelWrapper>

            <IconComponent
                width={12}
                height={18}
                color='white'
                opacity={1}
            />
        </ChipWrapper>
    );
}

export default Chip;