import React from 'react';
import styled from "styled-components";
import theme from "../../../config/theme"


const TagWrapper = styled.div`
  padding: 2px 10px;
  font-size: 10px;
  color: ${theme.colors.elements.tag.color};
  background: ${theme.colors.red};
  border-radius: 2px;
`;

const Tag = ({children}) => {
    return (
        <TagWrapper>
            {children}
        </TagWrapper>
    );
};

export default Tag;