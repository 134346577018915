import * as React from "react"

const Mail = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 22 18"
        width={width || 22}
        height={height || 18}
        fill="none"
        {...props}
    >
        <path
            d="m3 3 8 6 8-6M3 15l6-7m10 7-6-7M3 17h16a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Mail
