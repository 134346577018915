import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {checkAuth} from "../../actions/checkAuth";

export const checkAuthThunk = createAsyncThunk(
    "auth/checkAuthThunk",
    async () => {
        const response = await checkAuth();
        return response.data;
    }
)

const authSlice = createSlice({
    name: "auth",

    initialState: {
        isAuth: false,
        authPending: false
    },

    reducers: {
        logout(state) {
            state.isAuth = false;
            localStorage.removeItem("state");
            localStorage.removeItem("token");
        }
    },

    extraReducers: (builder) => {
        builder.addCase(checkAuthThunk.pending, (state) => {
            state.isAuth = false;
            state.authPending = true;
        });

        builder.addCase(checkAuthThunk.fulfilled, (state) => {
            state.isAuth = true;
            state.authPending = false;
        });

        builder.addCase(checkAuthThunk.rejected, (state) => {
            state.isAuth = false;
            state.authPending = false;
        });
    }
});

export default authSlice.reducer;

export const {
    logout
} = authSlice.actions;