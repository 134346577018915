import * as React from "react"
import styled from "styled-components";
import theme from "../../config/theme";


const PhotoIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const PhotoIcon = ({color, width, height, ...props}) => (
    <PhotoIconWrapper>
        <svg
            width={width || 12}
            height={height || 12}
            fill="none"
            {...props}
        >
            <path
                d="M12 10.667V1.333C12 .6 11.4 0 10.667 0H1.333C.6 0 0 .6 0 1.333v9.334C0 11.4.6 12 1.333 12h9.334C11.4 12 12 11.4 12 10.667ZM3.667 7l1.666 2.007L7.667 6l3 4H1.333l2.334-3Z"
                fill={color || theme.colors.grey}
                fillOpacity={0.5}
            />
        </svg>
    </PhotoIconWrapper>
)

export default PhotoIcon
