import * as React from "react"

const Projects = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 22 22"
        width={width || 22}
        height={height ||22}
        fill="none"
        {...props}
    >
        <path
            d="M7 5h-.5a2 2 0 0 0-2 2v6m10-8h.5a2 2 0 0 1 2 2v6m-8 4h4M9 9h4a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2ZM3 21h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2Zm12 0h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Projects