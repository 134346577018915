import React, {useState} from 'react';
import Input from "../../../components/UI/Input";
import handleInputSubmit from "../../../utils/handleInputSubmit";
import Dialog from "../../../components/UI/Dialog";
import connectDialog from "../../../hocs/modal";
import Button from "../../../components/UI/Button";
import DialogActions from "../../../components/UI/Dialog/DialogActions";
import DialogContent from "../../../components/UI/Dialog/DialogContent";
import theme from "../../../config/theme";

const AddProjectDialog = connectDialog("AddProjectDialog")((props) => {
    const {
        dialogOpen,
        dialogAgree,
        handleClose,
    } = props;

    const [fieldValue, setFieldValue] = useState("");

    function handleSubmit() {
        dialogAgree(fieldValue);
        handleClose();
    }

    return (
        <Dialog
            open={dialogOpen || false}
            title="Добавление проекта"
            onClose={handleClose}
        >
            <DialogContent>
                <Input
                    fullWidth
                    autoFocus
                    value={fieldValue}
                    placeholder="Название проекта"
                    onChange={value => setFieldValue(value)}
                    onKeyDown={(event) => handleInputSubmit(event, handleSubmit)}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    backgroundColor={theme.colors.font.grey}
                >
                    Отменить
                </Button>

                <Button onClick={() => handleSubmit()}>
                    Добавить проект
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default AddProjectDialog;