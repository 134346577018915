import * as React from "react"

const Charts = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 22 22"
        width={width || 22}
        height={height || 22}
        fill="none"
        {...props}
    >
        <path
            d="M11 11.5 15 3m-4 8.5L5 5m6 6.5h8.5M21 11c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Charts
