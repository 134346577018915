import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Masonry from "react-masonry-css";
import {DragDropContext} from "react-beautiful-dnd";
import {useDispatch, useSelector} from "react-redux";
import {
    closestCenter,
    DndContext,
    MouseSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {
    SortableContext,
    rectSwappingStrategy
} from "@dnd-kit/sortable";
import theme from "../../../config/theme";
import ProjectsColumn from "../../../components/ProjectsColumn";
import {
    movePlate,
    moveColumn,
    addPlatesListToUnassignedColumn
} from "../../../reducers/projects/columnsSlice";
import {setColumnsNumber} from "../../../reducers/projects/masonrySlice";
import {fetchEmployeesData} from "../../../actions/fetchEmployeesData";
import {
    addPlatesList,
    filterPlates
} from "../../../reducers/projects/platesSlice";

const ColumnsWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;

  @media (orientation: portrait) {
    flex-flow: wrap;
  }
`;

const UnassignedColumnWrapper = styled.div`
  flex-shrink: ${props => props.flexShrink};
  width: 100%;
  margin-right: ${theme.sizes.projectsPage.columns.marginRight};
`;

const Columns = (props) => {
    const {
        columnEditIconClick,
        columnDeleteIconClick
    } = props;

    const dispatch = useDispatch();
    const plates = useSelector(
        state => state.projects.platesSlice.plates
    );
    const columns = useSelector(
        state => state.projects.columnsSlice.columns
    );
    const columnsOrder = useSelector(
        state => state.projects.columnsSlice.columnsOrder
    );
    const columnsNumber = useSelector(
        state => state.projects.masonrySlice.columnsNumber
    );
    const [
        unassignedPlatesFilterValue, setUnassignedPlatesFilterValue
    ] = useState("");

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 1,
            },
        })
    );

    useEffect(() => {
        // ### temp - localstorage
        const fetchedColumns = localStorage.getItem("columns");
        const fetchedPlates = localStorage.getItem("plates");

        if (!fetchedColumns && !fetchedPlates) {
            setEmployeesData();
        }
    }, []);

    async function setEmployeesData() {
        const employeesData = await dispatch(fetchEmployeesData());
        dispatch(addPlatesList({plates: employeesData}));
        dispatch(addPlatesListToUnassignedColumn({plates: employeesData}));
    }

    useEffect(() => {
        dispatch(setColumnsNumber());
        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener);
        }
    }, []);

    function resizeListener() {
        dispatch(setColumnsNumber());
    }

    useEffect(() => {
        const unassignedColumn = columns["column-0"];
        const unassignedPlatesIds = unassignedColumn.platesIds;

        dispatch(filterPlates({
            searchText: unassignedPlatesFilterValue,
            platesIds: unassignedPlatesIds
        }));
    }, [unassignedPlatesFilterValue, columns["column-0"]]);

    function plateDragEndHandle({source, destination, draggableId}) {
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        dispatch(movePlate({source, destination, draggableId}));
    }

    function columnDragOverHandle({active, over}) {
        if (!over) {
            return;
        }

        const activeIndex = active.data.current.sortable.index;
        const overIndex = over.data.current.sortable.index;
        const activeId = active.id;
        const overId = over.id;

        dispatch(moveColumn({
            activeIndex,
            overIndex,
            activeId,
            overId
        }));
    }

    return (
        <ColumnsWrapper className="columns-wrapper">
            <DragDropContext
                onDragEnd={plateDragEndHandle}
            >
                <DndContext
                    onDragOver={columnDragOverHandle}
                    collisionDetection={closestCenter}
                    sensors={sensors}
                >
                    <SortableContext
                        id="columns-context"
                        items={columnsOrder}
                        strategy={rectSwappingStrategy}
                    >
                        <Masonry
                            breakpointCols={columnsNumber}
                            className="masonry-grid"
                            columnClassName="masonry-grid_column"
                        >
                            {columnsOrder.map((columnId, index) => {
                                if (index !== 0) {
                                    const column = columns[columnId];
                                    const localPlates =
                                        column.platesIds.map(
                                            plateId => plates[plateId]
                                        );
                                    return (
                                        <ProjectsColumn
                                            key={column.id}
                                            column={column}
                                            columnType="default"
                                            plates={localPlates}
                                            draggable={true}
                                            editIconClick={
                                                columnEditIconClick
                                            }
                                            deleteIconClick={
                                                columnDeleteIconClick
                                            }
                                        />
                                    );
                                }
                            })}
                        </Masonry>
                    </SortableContext>
                </DndContext>

                <UnassignedColumnWrapper
                    flexShrink={columnsNumber * 0.985}
                >
                    {columnsOrder.map((columnId, index) => {
                        if (index === 0) {
                            const column = columns[columnId];
                            const localPlates =
                                column.platesIds.map(plateId => plates[plateId]);
                            return (
                                <ProjectsColumn
                                    key={column.id}
                                    column={column}
                                    columnType="unassigned"
                                    plates={localPlates}
                                    draggable={false}
                                    editIconClick={
                                        columnEditIconClick
                                    }
                                    unassignedColumnInputChange={
                                        value => setUnassignedPlatesFilterValue(value)
                                    }
                                />
                            );
                        }
                    })}
                </UnassignedColumnWrapper>
            </DragDropContext>
        </ColumnsWrapper>
    );
};

export default Columns;