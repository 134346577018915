import React from 'react';
import styled from "styled-components";

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DialogActions = (props) => {
    const {
        children
    } = props;

    return (
        <ActionsWrapper>
            {children}
        </ActionsWrapper>
    );
};

export default DialogActions;