import * as React from "react"

const Portfolios = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 20 20"
        width={width || 20}
        height={height || 20}
        fill="none"
        {...props}
    >
        <path
            d="M5.5 11H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2.5m3.5 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-6m5-5h6a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2Zm0 8h6a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Portfolios
