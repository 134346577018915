import React, {useState} from 'react';
import Input from "../../../components/UI/Input";
import handleInputSubmit from "../../../utils/handleInputSubmit";
import Dialog from "../../../components/UI/Dialog";
import connectDialog from "../../../hocs/modal";
import Button from "../../../components/UI/Button";
import styled from "styled-components";
import theme from '../../../config/theme'
import {COLUMN_HEADER_COLORS} from "../../../config/consts";
import DialogContent from "../../../components/UI/Dialog/DialogContent";
import DialogActions from "../../../components/UI/Dialog/DialogActions";

const ColorsWrapper = styled.div`
  margin-top: 25px;
`;

const ColorsBlocksWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ColorsBlock = styled.div`
  cursor: pointer;
  width: 15px;
  height: 15px;
  background: ${props => theme.colors[props.color]};
  box-sizing: content-box;
  border: ${props => props.active 
          ? "3px solid" + theme.colors.darkBlue
          : " "
};
  
  :not(:last-child) {
    margin-right: 5px;
  }
`;

const EditProjectDialog = connectDialog("EditProjectDialog")((props) => {
    const {
        columnColor,
        dialogOpen,
        columnTitle,
        dialogAgree,
        handleClose,
    } = props;

    const [fieldValue, setFieldValue] = useState(columnTitle);
    const [currentColor, setCurrentColor] = useState(columnColor);

    function handleSubmit() {
        dialogAgree(fieldValue, currentColor);
        handleClose();
    }

    return (
        <Dialog
            open={dialogOpen || false}
            title="Редактирование проекта"
            onClose={handleClose}
        >
            <DialogContent>
                <Input
                    fullWidth
                    autoFocus
                    value={fieldValue}
                    placeholder="Название проекта"
                    onChange={value => setFieldValue(value)}
                    onKeyDown={(event) => handleInputSubmit(event, handleSubmit)}
                />

                <ColorsWrapper>
                    Цвет проекта:
                    <ColorsBlocksWrapper>
                        {COLUMN_HEADER_COLORS.map(color =>
                            <ColorsBlock
                                key={color}
                                color={color}
                                active={currentColor === color}
                                onClick={() => setCurrentColor(color)}
                            />
                        )}
                    </ColorsBlocksWrapper>
                </ColorsWrapper>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    backgroundColor={theme.colors.font.grey}
                >
                    Отменить
                </Button>

                <Button onClick={() => handleSubmit()}>
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default EditProjectDialog;