import * as React from "react"
import theme from "../../../config/theme";


const Exit = ({color, width, height, ...props}) => (
        <svg
            id="Outline"
            viewBox="0 0 512 512"
            width={width || 30}
            height={height || 30}
            fill='none'
            {...props}
        >
            <path
                fill={color || theme.colors.grey}
                d="m455.17 249.19-80.81-80.81a11.34 11.34 0 0 0-16 16l61.46 61.47H200.2a11.34 11.34 0 1 0 0 22.67h219.58L358.31 330a11.34 11.34 0 0 0 16 16l80.81-80.81a11.33 11.33 0 0 0 0-16Z"
            />
            <path
                fill={color || theme.colors.grey}
                d="M321.09 349.57a11.34 11.34 0 0 0-11.34 11.34V407a23 23 0 0 1-23 23H98.21a23 23 0 0 1-23-23V105.18a23 23 0 0 1 23-23h188.57a23 23 0 0 1 23 23v45a11.34 11.34 0 0 0 22.68 0v-45a45.7 45.7 0 0 0-45.65-45.65H98.21a45.7 45.7 0 0 0-45.65 45.65V407a45.7 45.7 0 0 0 45.65 45.65h188.57A45.7 45.7 0 0 0 332.43 407v-46.09a11.34 11.34 0 0 0-11.34-11.34Z"
            />
        </svg>
)

export default Exit
