import * as React from "react"

const Chat = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 22 22"
        width={width || 22}
        height={height || 22}
        fill="none"
        {...props}
    >
        <path
            d="M15 6H5m6 4H5M3 1h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-8l-4.36 3.633A1 1 0 0 1 5 19.865V17H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Chat
