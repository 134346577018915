import React, {useRef} from 'react';
import styled from "styled-components";
import {useDispatch} from "react-redux";
import TopBar from "./TopBar";
import Columns from "./Columns";
import {
    editColumn,
    deleteColumn
} from "../../reducers/projects/columnsSlice";
import {openDialog} from "../../reducers/root/dialogSlice";
import AddProjectDialog from "./dialogs/AddProjectDialog";
import AddEmployeeDialog from "./dialogs/AddEmployeeDialog";
import EditProjectDialog from "./dialogs/EditProjectDialog";
import DeleteProjectDialog from "./dialogs/DeleteProjectDialog";

const ProjectsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Projects = () => {
    const dispatch = useDispatch();
    let pickedColumnId = useRef(null);

    function columnEditIconClick(columnId, columnTitle, columnColor) {
        pickedColumnId = columnId;

        dispatch(openDialog({
            id: "EditProjectDialog",
            props: {
                columnColor,
                columnTitle,
                dialogAgree: projectEditDialogAgree
            }
        }));
    }

    function columnDeleteIconClick(columnId, columnTitle) {
        pickedColumnId = columnId;

        dispatch(openDialog({
            id: "DeleteProjectDialog",
            props: {
                projectName: columnTitle,
                dialogAgree: projectDeleteDialogAgree
            }
        }));
    }

    function projectEditDialogAgree(columnTitle, color) {
        dispatch(editColumn({columnTitle, color, columnId: pickedColumnId}));
    }

    function projectDeleteDialogAgree() {
        dispatch(deleteColumn({columnId: pickedColumnId}));
    }

    return (
        <ProjectsPageWrapper>
            <TopBar/>

            <Columns
                columnEditIconClick={columnEditIconClick}
                columnDeleteIconClick={columnDeleteIconClick}
            />

            <AddEmployeeDialog/>
            <AddProjectDialog/>
            <EditProjectDialog/>
            <DeleteProjectDialog/>
        </ProjectsPageWrapper>
    )
};

export default Projects;