import Projects from "../assets/icons/menu/Projects";
import Dashboard from "../assets/icons/menu/Dashboard";
import Tasks from "../assets/icons/menu/Tasks";
import Calendar from "../assets/icons/menu/Calendar";
import Chat from "../assets/icons/menu/Chat";
import Workload from "../assets/icons/menu/Workload";
import Charts from "../assets/icons/menu/Charts";
import Statistics from "../assets/icons/menu/Statistics";
import Mail from "../assets/icons/menu/Mail";
import MindMaps from "../assets/icons/menu/MindMaps";
import Spaces from "../assets/icons/menu/Spaces";
import Timeline from "../assets/icons/menu/Timeline";
import Portfolios from "../assets/icons/menu/Portfolios";

export const bodyItems = [
    {
        id: "dashboard",
        icon: Dashboard,
        name: "Панель инструментов"
    },
    {
        id: "tasks",
        icon: Tasks,
        name: "Задачи"
    },
    {
        id: "projects",
        icon: Projects,
        name: "Проекты"
    },
    {
        id: "calendar",
        icon: Calendar,
        name: "Календарь"
    },
    {
        id: "chat",
        icon: Chat,
        name: "Чат"
    },
    {
        id: "mindmaps",
        icon: MindMaps,
        name: "Карта сайта"
    },
    {
        id: "workload",
        icon: Workload,
        name: "Нагрузка"
    },
    {
        id: "spaces",
        icon: Spaces,
        name: "Зоны"
    },
    {
        id: "timeline",
        icon: Timeline,
        name: "Сроки"
    },
    {
        id: "charts",
        icon: Charts,
        name: "Графики"
    },
    {
        id: "statistic",
        icon: Statistics,
        name: "Статистика"
    },
    {
        id: "portfolios",
        icon: Portfolios,
        name: "Портфолио"
    },
    {
        id: "mail",
        icon: Mail,
        name: "Почта"
    }
]