import {createSlice} from "@reduxjs/toolkit";
import theme from "../../config/theme";

const masonrySlice = createSlice({
    name: "masonry",

    initialState: {
        columnsNumber: 0
    },

    reducers: {
        setColumnsNumber(state) {
            const columnsMarginRight = parseInt(
                theme.sizes.projectsPage.columns.marginRight
            );
            const columnMargin = parseInt(
                theme.sizes.projectsPage.column.margin
            );
            const plateMinWidth = parseInt(
                theme.sizes.projectsPage.plate.minWidth
            );
            const platePadding = parseInt(
                theme.sizes.projectsPage.plate.padding
            );
            const columnsWrapperWidth =
                document.getElementsByClassName(
                    "columns-wrapper"
                )[0].clientWidth;

            const availableWidth = columnsWrapperWidth - columnsMarginRight;
            const columnMinWidth = plateMinWidth + (platePadding * 2);
            const columnsMaxNumber = Math.floor(
                availableWidth / columnMinWidth
            );
            const columnsSideMargins = columnMargin * (columnsMaxNumber - 1);
            const requiredWidth = (columnsMaxNumber * columnMinWidth)
                + columnsSideMargins;

            if (availableWidth >= requiredWidth) {
                state.columnsNumber = columnsMaxNumber - 1;
            } else {
                state.columnsNumber = columnsMaxNumber - 2;
            }
        }
    }
});

export default masonrySlice.reducer;
export const {setColumnsNumber} = masonrySlice.actions;