import React, {useState} from 'react';
import styled from "styled-components";
import theme from "../../../config/theme";
import Input from "../../UI/Input";
import Tooltip from "../../UI/Tooltip";
import EditIcon from "../../../assets/icons/EditIcon";
import PointsIcon
    from "../../../assets/icons/PointsIcon";

const borderRadius = theme.sizes.borderRadius.default;
const topLineBorderRadius = borderRadius + " " + borderRadius + " 0 0";
const borderRadiusBottom = "0 0 " + borderRadius + " " + borderRadius;

const ColumnHeaderWrapper = styled.div`
  margin-bottom: 20px;
  word-break: break-word;
  text-align: start;
  user-select: none;
`;

const HeaderTopLine = styled.div`
  width: 100%;
  height: 4px;
  background: ${props => props.color};
  border-radius: ${topLineBorderRadius};
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background: ${theme.colors.elements.backgroundColor};
  border-radius: ${borderRadiusBottom};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.span`
  margin-left: 10px;
  font-size: 1.2em;
  font-weight: ${theme.sizes.fontWeight.bold};
`;

const InputWrapper = styled.div`
  margin-top: 10px;
`;

const UnassignedColumnHeader = (props) => {
    const {
        title,
        topLineColor,
        inputChange,
        editIconClick
    } = props;

    const [fieldValue, setFieldValue] = useState("");

    function changeHandler(value) {
        setFieldValue(value);
        inputChange(value);
    }

    return (
        <ColumnHeaderWrapper>
            <HeaderTopLine color={topLineColor}/>

            <HeaderContent>
                <TitleWrapper>
                    <PointsIcon/>
                    <HeaderTitle>
                        {title}
                    </HeaderTitle>
                </TitleWrapper>
                <Tooltip text='Изменить'>
                    <EditIcon
                        onClick={editIconClick}
                    />
                </Tooltip>
            </HeaderContent>

            <InputWrapper>
                <Input
                    fullWidth
                    value={fieldValue}
                    placeholder="Имя сотрудника"
                    onChange={value => changeHandler(value)}
                />
            </InputWrapper>
        </ColumnHeaderWrapper>
    );
};

export default UnassignedColumnHeader;