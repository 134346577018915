import * as React from "react"

const Tasks = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 16 20"
        width={width || 16}
        height={height || 20}
        fill="none"
        {...props}
    >
        <path
            d="M5 10.5 7.5 13 11 9.5M11 3a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2m10 2v13a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Tasks
