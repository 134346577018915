import React from 'react';
import styled from "styled-components";
import theme from "../../../config/theme"


const BodyWrapper = styled.div`
  padding: 10px 0 10px 20px;
  color: ${theme.colors.elements.input.placeholder};
`;

const PlateTitle = styled.div`
  position: relative;
  font-weight: ${theme.sizes.fontWeight.bold};
  line-height: 20px;
`;

const TitleMarker = styled.div`
  position: absolute;
  top: 6px;
  left: -18px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${theme.colors.blue};
`;

const PlateDescription = styled.div`
  line-height: 150%;
`;

const PlateBody = ({task}) => {
    return (
        <BodyWrapper>

            <PlateTitle>
                <TitleMarker/>
                {task.title}
            </PlateTitle>
            <PlateDescription>{task.desc}</PlateDescription>
        </BodyWrapper>
    );
};

export default PlateBody;