import React from 'react';
import Dialog from "../../../components/UI/Dialog";
import connectDialog from "../../../hocs/modal";
import Button from "../../../components/UI/Button";
import DialogContent from "../../../components/UI/Dialog/DialogContent";
import DialogActions from "../../../components/UI/Dialog/DialogActions";
import theme from "../../../config/theme";

const DeleteProjectDialog = connectDialog("DeleteProjectDialog")((props) => {
    const {
        dialogOpen,
        projectName,
        dialogAgree,
        handleClose,
    } = props;

    function handleSubmit() {
        dialogAgree();
        handleClose();
    }

    return (
        <Dialog
            open={dialogOpen || false}
            title="Удаление проекта"
            onClose={handleClose}
        >
            <DialogContent>
                <div>
                    <span>Удалить проект '{projectName}'?</span>
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    backgroundColor={theme.colors.font.grey}
                >
                    Отменить
                </Button>

                <Button
                    autoFocus
                    onClick={() => handleSubmit()}
                >
                    Удалить проект
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default DeleteProjectDialog;