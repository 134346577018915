import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {closeDialog} from "../reducers/root/dialogSlice";

const connectDialog = (dialogId) => (Component) => {
    return () => {
        const dispatch = useDispatch();
        const dialogs = useSelector(
            state => state.root.dialogSlice.dialogs
        );

        const dialog = dialogs[dialogId];
        const dialogOpen = dialog?.open;
        const dialogProps = dialog?.props;

        if (!dialog || !dialogOpen) {
            return null;
        }

        return (
            <Component
                handleClose={() => dispatch(closeDialog(dialogId))}
                dialogOpen={dialogOpen}
                {...dialogProps}
            />
        );
    }
};

export default connectDialog;