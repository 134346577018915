import React, {useState} from 'react';
import styled from 'styled-components'
import theme from "../../../config/theme";


const TooltipWrapper = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover::after {
    content: '';
    cursor: auto;
    position: absolute;
    bottom: -23px;
    left: 15%;
    display: block;
    background-color: ${theme.colors.elements.tooltip.backgroundColor};
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
  }
`;

const TooltipBox = styled.div`
  display: ${props => props.isVisible ? 'block' : 'none'};
  cursor: auto;
  font-size: 8px;
  padding: 2px 5px;
  position: absolute;
  bottom: -40px;
  left: -150%;
  background-color: ${theme.colors.elements.tooltip.backgroundColor};
  border-radius: ${theme.sizes.borderRadius.default};
  width: max-content;
  color: white;
`;

const Tooltip = ({children, text}) => {
    const [visible, setVisible] = useState(false);

    return (
        <TooltipWrapper
            onMouseOver={() => setVisible(true)}
            onMouseOut={() => setVisible(false)}
        >
            <TooltipBox isVisible={visible}>
                {text}
            </TooltipBox>
            {children}
        </TooltipWrapper>
    );
};

export default Tooltip;