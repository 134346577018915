import React from 'react';
import styled from "styled-components";
import StarIcon from '../../../assets/icons/StarIcon';
import Tag from "../../UI/Tag";


const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PlateHeader = ({tags}) => {
    return (
        <HeaderWrapper>
            {tags.map(tag =>
                <Tag key={tag}>{tag}</Tag>
            )}
            <StarIcon/>
        </HeaderWrapper>
    );
};

export default PlateHeader;