import * as React from "react"
import theme from "../../config/theme";
import styled from 'styled-components'


const StarIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const StarIcon = ({color, width, height, ...props}) => (
    <StarIconWrapper>
        <svg
            viewBox="0 0 18 17"
            width={width || 18}
            height={height || 17}
            fill="none"
            {...props}
        >
            <path
                d="m9 13.392 5.15 3.108-1.367-5.858 4.55-3.942-5.991-.508L9 .667 6.658 6.192.667 6.7l4.55 3.942L3.85 16.5 9 13.392Z"
                fill={color || theme.colors.grey}
                fillOpacity={0.5}
            />
        </svg>
    </StarIconWrapper>
)

export default StarIcon
