import * as React from "react"

const Workload = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 20 17"
        width={width || 20}
        height={height || 17}
        fill="none"
        {...props}
    >
        <path
            d="M2 14h16m-6.827-3.25 2.154-5.988M1 10a9 9 0 0 1 18 0m-9 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Workload
