import * as React from "react"

const Dashboard = ({color, width, height, ...props}) => (
    <svg
        viewBox="0 0 20 20"
        width={width || 20}
        height={height || 20}
        fill="none"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 2H2v3h5V2ZM2 0a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2ZM18 14h-5v4h5v-4Zm-5-2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-5ZM18 2h-5v6h5V2Zm-5-2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-5ZM7 11H2v7h5v-7ZM2 9a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2H2Z"
            fill={color}
        />
    </svg>
)

export default Dashboard
