import React from 'react';
import styled from "styled-components";
import theme from "../../../config/theme";
import EditIcon from '../../../assets/icons/EditIcon';
import Tooltip from "../../UI/Tooltip";
import RemoveIcon from "../../../assets/icons/RemoveIcon";
import PointsIcon
    from "../../../assets/icons/PointsIcon";

const topLineBorderRadius = theme.sizes.borderRadius.default + " " +
    theme.sizes.borderRadius.default + " 0 0";

const ColumnHeaderWrapper = styled.div`
  margin-bottom: 20px;
  border-radius: ${theme.sizes.borderRadius.default};
  background: ${theme.colors.elements.backgroundColor};
  word-break: break-word;
  text-align: start;
  user-select: none;
`;

const HeaderTopLine = styled.div`
  width: 100%;
  height: 4px;
  background: ${props => props.color};
  border-radius: ${topLineBorderRadius};
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.span`
  margin-left: 10px;
  font-size: 1.2em;
  font-weight: ${theme.sizes.fontWeight.bold};
`;

const IconsWrapper = styled.div`
  display: flex;
`;

const ColumnHeader = (props) => {
    const {
        title,
        topLineColor,
        editIconClick,
        deleteIconClick
    } = props;

    return (
        <ColumnHeaderWrapper>
            <HeaderTopLine color={topLineColor}/>

            <HeaderContent>
                <TitleWrapper>
                    <PointsIcon/>
                    <HeaderTitle>
                        {title}
                    </HeaderTitle>
                </TitleWrapper>

                <IconsWrapper>
                    <Tooltip text='Изменить'>
                        <EditIcon onClick={editIconClick}/>
                    </Tooltip>
                    <Tooltip text='Удалить'>
                        <RemoveIcon onClick={deleteIconClick}/>
                    </Tooltip>
                </IconsWrapper>
            </HeaderContent>
        </ColumnHeaderWrapper>
    );
};

export default ColumnHeader;