import * as React from "react"

const Timeline = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 22 22"
        width={width || 22}
        height={height || 22}
        fill="none"
        {...props}
    >
        <path
            d="M15 4h6M1 4h2m-2 7h8m4 7h8M5 7h8a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2Zm6 7h8a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2Zm-8 7h8a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Timeline
