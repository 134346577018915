import React from 'react';

const Statistic = () => {
    return (
        <div>
            <h1>STATISTIC PAGE</h1>
        </div>
    );
};

export default Statistic;