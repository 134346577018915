import React from 'react';
import styled from "styled-components";
import PlateHeader from "./PlateHeader";
import PlateBody from "./PlateBody";
import PlateFooter from "./PlateFooter";
import {Draggable} from "react-beautiful-dnd";
import theme from "../../config/theme"

const PlateWrapper = styled.div`
  margin-top: 10px;
  padding: 10px;
  background: ${theme.colors.elements.backgroundColor};
  border-radius: ${theme.sizes.borderRadius.default};
`;

const WorkloadPlate = ({task, index}) => {
    return (
        <Draggable
            draggableId={task.id}
            index={index}
        >
            {(provided, snapshot) => (
                <PlateWrapper
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                >
                    <PlateHeader
                        tags={task.tagsList}
                    />
                    <PlateBody task={task}/>
                    <PlateFooter avatars={task.avatarsList}/>
                </PlateWrapper>
            )}
        </Draggable>
    );
};

export default WorkloadPlate;