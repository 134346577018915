import axios from "../middleware/axios";
import getUrlFromPath from "../utils/getUrlFromPath";
import {authPaths} from "../config/paths";

export function getRedirect() {
    return axios({
        method: "GET",
        url: getUrlFromPath(authPaths.REDIRECT),
        headers: {
            "Origin-custom": window.location.origin
        }
    });
}