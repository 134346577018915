import React from 'react';
import styled from "styled-components";

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2em;
`;

const Main = () => {
    return (
        <MainWrapper>
            Главная страница.
        </MainWrapper>
    );
};

export default Main;