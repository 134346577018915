import employeesData from "../assets/projectsData/employeesData.json";

export function fetchEmployeesData() {
    return async function() {
        return employeesData;
    }
}

/*
import axios from "../middleware/axios";
import getUrlFromPath from "../utils/getUrlFromPath";
import {projectsPaths} from "../config/paths";

export function fetchEmployeesData() {
    return axios({
        method: "GET",
        url: getUrlFromPath(projectsPaths.GET_EMPLOYEES_DATA),
        params: {
            limit: "1000",
            offset: "0",
            sort: "name_first_name name_last_name"
        }
    });
}
*/