import React from 'react';
import styled from "styled-components";
import CrossIcon from "../../../assets/icons/CrossIcon";
import Tooltip from "../../UI/Tooltip";

const PlateHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const TagsWrapper = styled.div`
  width: 50px;
`;

const PlateHeader = (props) => {
    const {
        onRemoveIconClick
    } = props;

    return (
        <PlateHeaderWrapper>
            <TagsWrapper/>
            <Tooltip text='Удалить'>
                <CrossIcon
                    onClick={onRemoveIconClick}
                />
            </Tooltip>
        </PlateHeaderWrapper>
    );
};

export default PlateHeader;