import React from 'react';
import styled from "styled-components";
import theme from "../../config/theme";
import WorkloadPlate from "../WorkloadPlate";
import {Droppable} from "react-beautiful-dnd";
import ColumnHeader from "./ColumnHeader";

const borderRadius = theme.sizes.borderRadius.default;
const topLineBorderRadius = borderRadius + " " + borderRadius + " 0 0";

const ColumnWrapper = styled.div`
  width: 395px;
  padding-bottom: 10px;

  :not(:last-child) {
    margin-right: 10px;
  }
`;

const HeaderWrapper = styled.div`

`;

const HeaderTopLine = styled.div`
  height: 3px;
  border-radius: ${topLineBorderRadius};
  background: ${props => props.color};
`;

const ColumnBody = styled.div`
  width: 100%;
`;

const WorkloadColumn = ({title, column, tasks}) => {
    return (
        <ColumnWrapper>
            <HeaderWrapper>
                <HeaderTopLine color={theme.colors[column.color]}/>
                <ColumnHeader title={title}/>
            </HeaderWrapper>

            <Droppable droppableId={column.id}>
                {(provided) => (
                    <ColumnBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {tasks.map((task, index) =>
                            <WorkloadPlate
                                key={task.id}
                                task={task}
                                index={index}
                            />
                        )}
                        {provided.placeholder}
                    </ColumnBody>
                )}
            </Droppable>
        </ColumnWrapper>
    );
};

export default WorkloadColumn;