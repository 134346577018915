import {configureStore} from "@reduxjs/toolkit";
import projectsReducers from "./projects";
import workloadReducers from "./workload";
import rootReducers from "./root";
import checkLocation from "../utils/checkLocation";

export const store = configureStore({
    reducer: {
        root: rootReducers,
        projects: projectsReducers,
        workload: workloadReducers
    },
    devTools: checkLocation() === "local",
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
        {serializableCheck: false}
    )
});