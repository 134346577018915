import * as React from "react"

const PointsIcon = (props) => (
    <svg
        width={6}
        height={14}
        fill="none"
        {...props}
    >
        <circle cx={1} cy={1} r={1} fill="#5687AD" fillOpacity={0.5} />
        <circle cx={5} cy={1} r={1} fill="#5687AD" fillOpacity={0.5} />
        <circle cx={1} cy={5} r={1} fill="#5687AD" fillOpacity={0.5} />
        <circle cx={5} cy={5} r={1} fill="#5687AD" fillOpacity={0.5} />
        <circle cx={1} cy={9} r={1} fill="#5687AD" fillOpacity={0.5} />
        <circle cx={1} cy={13} r={1} fill="#5687AD" fillOpacity={0.5} />
        <circle cx={5} cy={9} r={1} fill="#5687AD" fillOpacity={0.5} />
        <circle cx={5} cy={13} r={1} fill="#5687AD" fillOpacity={0.5} />
    </svg>
)

export default PointsIcon
