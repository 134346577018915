import React from 'react';
import styled from "styled-components";
import theme from "../../../../config/theme";


const TaskIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const Count = styled.div`
  margin-left: 8px;
  font-size: 10px;
  line-height: 16px;
  color: ${theme.colors.font.grey};
`;

const TaskIcon = ({icon, count}) => {
    const Icon = icon;

    return (
        <TaskIconWrapper>
            <Icon/>
            <Count>{count}</Count>
        </TaskIconWrapper>
    );
};

export default TaskIcon;