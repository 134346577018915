import Projects from "../pages/Projects";
import Dashboard from "../pages/Dashboard";
import Tasks from "../pages/Tasks";
import Calendar from "../pages/Calendar";
import Chat from "../pages/Chat";
import Workload from "../pages/Workload";
import Charts from "../pages/Charts";
import Statistic from "../pages/Statistic";
import Mail from "../pages/Mail";
import Main from "../pages/Main";
import NotFound from "../pages/NotFound";
import Auth from "../pages/Auth";

export const protectedRoutes = [
    {path: "/dashboard", element: <Dashboard/>},
    {path: "/tasks", element: <Tasks/>},
    {path: "/projects", element: <Projects/>},
    {path: "/calendar", element: <Calendar/>},
    {path: "/chat", element: <Chat/>},
    {path: "/workload", element: <Workload/>},
    {path: "/charts", element: <Charts/>},
    {path: "/statistic", element: <Statistic/>},
    {path: "/mail", element: <Mail/>},
    {path: "/", element: <Main/>},
    {path: "*", element: <NotFound/>}
]

export const publicRoutes = [
    {path: "*", element: <Auth/>},
]