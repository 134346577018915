import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {BrowserRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import BackgroundFigures from "./components/BackgroundFigures";
import SideMenu from "./components/SideMenu";
import ProtectedRoutes from "./router/ProtectedRoutes";
import PublicRoutes from "./router/PublicRoutes";
import {checkAuthThunk} from "./reducers/root/authSlice";
import {getToken} from "./actions/getToken";
import Loader from "./components/UI/Loader";
import applyToken from "./utils/applyToken";

const AppWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 10px;
`;

function App() {
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.root.authSlice.isAuth);
    const authPending = useSelector(
        state => state.root.authSlice.authPending
    );
    const [token, setToken] = useState(localStorage.getItem("token"));

    useEffect(async () => {
        const state = localStorage.getItem("state");
        if (state && !token) {
            const response = await getToken(state);
            if (response) {
                setToken("Bearer " + response.data.access_token);
            }
        }
    }, []);

    useEffect(() => {
        if (token) {
            applyToken(token);
            dispatch(checkAuthThunk());
        }
    }, [token]);

    function getPrivateContent() {
        return (
            <>
                <SideMenu/>
                <ProtectedRoutes/>
            </>
        );
    }

    function getPublicContent() {
        return authPending ? <Loader/> : <PublicRoutes/>;
    }

    return (
        <AppWrapper>
            <BackgroundFigures/>



            <ContentWrapper>
                <BrowserRouter>
                    {isAuth
                        ? getPrivateContent()
                        : getPublicContent()
                    }
                </BrowserRouter>
            </ContentWrapper>
        </AppWrapper>
    );
}

export default App;