import React from 'react';
import styled from "styled-components";

const NotFoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.5em;
`;

const NotFound = () => {
    return (
        <NotFoundWrapper>
            Страница с таким адресом не найдена.
            Воспользуйтесь меню для навигации.
        </NotFoundWrapper>
    );
};

export default NotFound;