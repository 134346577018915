import * as React from "react"

const Spaces = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 20 20"
        width={width || 20}
        height={height || 20}
        fill="none"
        {...props}
    >
        <path
            d="M1 2a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2Zm0 11a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-5Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Spaces
