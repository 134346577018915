import React from 'react';
import styled from "styled-components";
import theme from "../../../config/theme"


const AvatarWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${theme.colors.elements.avatar.backgroundColor};
  font-weight: ${theme.sizes.fontWeight.bold};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  :not(:last-child) {
    margin-right: 4px;
  }
`;

const AvatarTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 6.5px;
  height: 6.5px;
  border-radius: 50%;
  background-color: ${theme.colors.red};
`;

const Avatar = ({children}) => {
    return (
        <AvatarWrapper>
            <AvatarTag/>
            {children}
        </AvatarWrapper>
    );
};

export default Avatar;