import React from 'react';
import styled from "styled-components";

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 100px;
  height: 100px;
`;

const LoaderElement = styled.div`
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  margin: 10px;
  border: 10px solid;
  border-radius: 50%;
  border-color: #fff transparent transparent transparent;
  animation: circle-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  
  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes circle-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = () => {
    return (
        <LoaderWrapper>
            <LoaderElement/>
            <LoaderElement/>
            <LoaderElement/>
            <LoaderElement/>
        </LoaderWrapper>
    );
};

export default Loader;