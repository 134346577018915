import {createSlice} from "@reduxjs/toolkit";
import {
    WORKLOAD_COLUMNS_ORDER_DEFAULT,
    WORKLOAD_COLUMNS_DEFAULT
} from "../../config/consts";

const columns = WORKLOAD_COLUMNS_DEFAULT
const columnsOrder = WORKLOAD_COLUMNS_ORDER_DEFAULT

const workloadColumnsSlice = createSlice({
    name: 'workloadColumns',

    initialState: {
        columns,
        columnsOrder
    },

    reducers: {}
})

export default workloadColumnsSlice.reducer;

export const {} = workloadColumnsSlice.actions;