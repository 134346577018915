import workloadColumns from '../assets/workloadData/workloadColumns.json'
import workloadColumnsOrder from '../assets/workloadData/workloadColumnsOrder.json'
import workloadPlates from '../assets/workloadData/workloadPlates.json'

export const BASE_URL_SERVER = "https://crm3desk-dev.eztek.ru/";
export const BASE_URL_LOCAL = "/";

export const COLUMN_HEADER_COLORS = [
    "orange", "red", "blue", "violet", "green", "turquoise"
];

export const PROJECTS_COLUMNS_DEFAULT = {
    "column-0": {
        id: "column-0",
        title: "Неназначенные",
        color: "orange",
        platesIds: []
    }
};
export const PROJECTS_COLUMNS_ORDER_DEFAULT = ["column-0"];
export const PROJECTS_PLATES_DEFAULT = {};

export const WORKLOAD_COLUMNS_DEFAULT = workloadColumns;
export const WORKLOAD_COLUMNS_ORDER_DEFAULT = workloadColumnsOrder;
export const WORKLOAD_PLATES_DEFAULT = workloadPlates;