import React from 'react';
import styled from "styled-components";
import theme from "../../../config/theme";

const SideMenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 35px;
  border-radius: 25px;
  background: ${props => props.background};
  cursor: pointer;
  transition: background 0.5s ease;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const CustomIconWrapper = styled.div`
  margin-right: 15px;
  display: flex;
`;

const TextWrapper = styled.div`
  color: ${props => props.color};
  font-weight: ${theme.sizes.fontWeight.bold};
  transition: color 0.5s ease;
`;

const SideMenuItem = (props) => {
    const {
        id,
        icon,
        name,
        picked,
        onItemClick
    } = props;

    const Icon = icon;

    let itemBackground = "none";
    let iconColor = theme.colors.grey;
    let iconOpacity = 0.5
    let fontColor = theme.colors.lightgrey;

    if (picked) {
        itemBackground = theme.colors.elements.sideMenu.itemBackgroundColor;
        iconColor = theme.colors.orange;
        iconOpacity = 1
        fontColor = theme.colors.font.dark;
    }

    function itemClick() {
        onItemClick(id);
    }

    return (
        <SideMenuItemWrapper
            background={itemBackground}
            onClick={itemClick}
        >
            <CustomIconWrapper>
                <Icon
                    color={iconColor}
                    opacity={iconOpacity}
                />
            </CustomIconWrapper>

            <TextWrapper color={fontColor}>
                {name}
            </TextWrapper>
        </SideMenuItemWrapper>
    );
};

export default SideMenuItem;