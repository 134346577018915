import React from 'react';
import styled from "styled-components";
import {Draggable} from "react-beautiful-dnd";
import {useDispatch} from "react-redux";
import theme from "../../config/theme";
import PlateHeader from "./PlateHeader";
import PlateBody from "./PlateBody";
import {removePlateFromColumn} from "../../reducers/projects/columnsSlice";
import {removePlate} from "../../reducers/projects/platesSlice";

const PlateWrapper = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  flex-direction: column;
  min-width: ${theme.sizes.projectsPage.plate.minWidth};
  height: auto;
  margin-bottom: 10px;
  padding: ${theme.sizes.projectsPage.plate.padding};
  background: ${theme.colors.elements.backgroundColor};
  opacity: ${props => props.isDragging ? 0.8 : 1};
  border: ${props => props.isDragging
          ? "2px solid " + theme.colors.blue
          : "0px solid " + theme.colors.blue};
  box-shadow: ${props => props.isSearched
          ? "0px 0px 20px " + theme.colors[props.boxShadowColor]
          : null};
  border-radius: 10px;
  transition: opacity 0.25s ease, border 0.25s ease, box-shadow 0.25s ease;

  @media (orientation: portrait) {
    width: 100%;
  }
`;

const ProjectsPlate = (props) => {
    const {
        plateBodyData,
        index,
        columnId,
        columnColor
    } = props;

    const dispatch = useDispatch();

    function removeIconClick() {
        dispatch(removePlate({plateId: plateBodyData.id}));
        dispatch(removePlateFromColumn({
            plateId: plateBodyData.id,
            columnId: columnId
        }));
    }

    return (
        <Draggable
            draggableId={plateBodyData.id}
            index={index}
        >
            {(provided, snapshot) => (
                <PlateWrapper
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    visible={plateBodyData.visible}
                    isSearched={plateBodyData.searched}
                    boxShadowColor={columnColor}
                >
                    <PlateHeader
                        onRemoveIconClick={removeIconClick}
                    />

                    <PlateBody
                        plateBodyData={plateBodyData}
                    />
                </PlateWrapper>
            )}
        </Draggable>
    );
};

export default ProjectsPlate;