import React from 'react';
import styled from "styled-components";
import theme from "../../../config/theme";
import Tooltip from "../Tooltip";
import CrossIcon from "../../../assets/icons/CrossIcon";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  font-size: 14px;
  font-weight: ${theme.sizes.fontWeight.bold};
`;

const DialogHeader = (props) => {
    const {
        children,
        onClose
    } = props;

    return (
        <HeaderWrapper>
            <TitleWrapper>
                {children}
            </TitleWrapper>

            <Tooltip text='Закрыть'>
                <CrossIcon
                    onClick={onClose}
                />
            </Tooltip>
        </HeaderWrapper>
    );
};

export default DialogHeader;