import * as React from "react"
import theme from "../../config/theme";
import styled from "styled-components";


const PlusWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const PlusIcon = ({color, width, height, opacity, ...props}) => (
    <PlusWrapper>
        <svg
            viewBox="0 0 15 22"
            width={width || 15}
            height={height || 22}
            fill="none"
            {...props}
        >
            <path
                d="M7.071 4v14.142M14.142 11.071H0"
                stroke={color || theme.colors.grey}
                strokeOpacity={opacity || 0.5}
                strokeWidth={2}
            />
        </svg>
    </PlusWrapper>
)

export default PlusIcon
