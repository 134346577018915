import * as React from "react"

const MindMaps = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 22 22"
        width={width || 22}
        height={height || 22}
        fill="none"
        {...props}
    >
        <path
            d="M14 4.5h-1a2 2 0 0 0-2 2V9a2 2 0 0 1-2 2 2 2 0 0 1 2 2v2.5a2 2 0 0 0 2 2h1M3 15h4a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2Zm13-7h3a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-3a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2Zm0 13h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2h-3a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default MindMaps
