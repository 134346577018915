import React from 'react';
import styled from "styled-components";
import Button from "../../components/UI/Button";
import Workspace from "../../assets/icons/menu/Workspace";
import theme from "../../config/theme";
import {getRedirect} from "../../actions/getRedirect";
import getUrlParameter from "../../utils/getUrlParameter";

const AuthPageWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const TitleTextWrapper = styled.div`
  margin-left: 15px;
  font-weight: ${theme.sizes.fontWeight.bold};
  font-size: 14px;
  color: black;
`;

const Auth = () => {
    async function authButtonClick() {
        const response = await getRedirect();
        if (response) {
            const state = getUrlParameter("state", response.data);
            localStorage.setItem("state", state);
            window.location.href = response.data;
        }
    }

    return (
        <AuthPageWrapper>
            <TitleWrapper>
                <Workspace/>
                <TitleTextWrapper>Workspace</TitleTextWrapper>
            </TitleWrapper>

            <Button
                autoFocus
                fullWidth
                onClick={authButtonClick}
            >
                <span>Войти через сервис авторизации</span>
            </Button>
        </AuthPageWrapper>
    );
};

export default Auth;