import React from 'react';
import styled from "styled-components";
import theme from "../../../config/theme";

const HeaderField = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${theme.sizes.fontWeight.bold};
  margin-bottom: 5px;
  user-select: none;
`;

const PlateTitleDot = styled.div`
  width: 7px;
  height: 7px;
  aspect-ratio: 1;
  margin-right: 8px;
  border-radius: 50%;
  background: ${theme.colors.blue};
`;

const DefaultField = styled.div`
  margin-bottom: 5px;
  user-select: none;
`;

const PlateBody = (props) => {
    const {
        plateBodyData
    } = props;

    return (
        <>
            {Object.keys(plateBodyData).map(field => {
                    if (
                        field === "id"
                        || field === "searched"
                        || field === "visible"
                    ) {
                        return null;
                    }

                    if (field === "name") {
                        return (
                            <HeaderField
                                key={plateBodyData[field].value}
                            >
                                <PlateTitleDot/>
                                {plateBodyData[field].value}
                            </HeaderField>
                        );
                    } else {
                        return (
                            <DefaultField
                                key={plateBodyData[field].value}
                            >
                                {plateBodyData[field].key}
                                {plateBodyData[field].value}
                            </DefaultField>
                        );
                    }
                }
            )}
        </>
    );
};

export default PlateBody;