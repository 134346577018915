import React from 'react';
import styled from "styled-components";
import {useNavigate} from 'react-router-dom';
import theme from "../../config/theme";
import SideMenuHeader from "./SideMenuHeader";
import SideMenuItem from "./SideMenuItem";
import {bodyItems} from "../../config/menu";
import {logout} from "../../reducers/root/authSlice";
import {useDispatch} from "react-redux";
import Exit from "../../assets/icons/menu/Exit";

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: ${theme.sizes.menu.width};
  height: 100%;
  margin: 0 20px 0 0;
  padding: 5px;
  background: ${theme.colors.elements.backgroundColor};
  border-radius: 10px;

  @media (orientation: portrait) {
    width: 100%;
    height: auto;
    margin: 0 0 20px 0;
  }
`;

const MenuItemsWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-right: 5px;
`;

const MenuItemExitWrapper = styled.div`
  flex-grow: 0;
  margin: 10px 0 5px 0;
  padding-right: 10px;
`;

const SideMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function itemClick(id) {
        navigate("/" + id);
    }

    function logoutHandle() {
        dispatch(logout());
        navigate('/auth');
    }

    return (
        <MenuWrapper>
            <SideMenuHeader headerTitle="Меню"/>
            <MenuItemsWrapper>
                {bodyItems.map(item =>
                    <SideMenuItem
                        id={item.id}
                        key={item.id}
                        icon={item.icon}
                        name={item.name}
                        picked={item.id === window.location.pathname.replace(
                            "/", ""
                        )}
                        onItemClick={itemClick}
                    />
                )}
            </MenuItemsWrapper>

            <MenuItemExitWrapper>
                <SideMenuItem
                    id={'Exit'}
                    icon={Exit}
                    name={'Выйти'}
                    onItemClick={logoutHandle}
                    picked={true}
                />
            </MenuItemExitWrapper>
        </MenuWrapper>
    );
};

export default SideMenu;