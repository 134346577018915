import axios from "axios";
import applyToken from "../../utils/applyToken";

axios.defaults.withCredentials = true;

axios.interceptors.response.use((response) => {
    if (!response.data) {
        console.log("Error! No data in response.");
        return null;
    }

    const token = response.data.access_token;
    if (token) {
        applyToken(token);
    }

    return response;
}, (error) => {
    console.log(error);
    return null;
});

export default axios;