import React from 'react';
import styled from "styled-components";
import Avatar from "../../UI/Avatar";
import TaskIcon from "../../UI/Correspondence/TaskIcon";
import ChatBubbleIcon from "../../../assets/icons/ChatBubbleIcon";
import PhotoIcon from "../../../assets/icons/PhotoIcon";


const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

const FooterLeft = styled.div`
  width: 80px;
  display: flex;
  justify-content: space-between;
`;

const FooterRight = styled.div`
  display: flex;
`;

const PlateFooter = ({avatars}) => {
    return (
        <FooterWrapper>
            <FooterLeft>
                <TaskIcon
                    icon={ChatBubbleIcon}
                    count='10'
                />
                <TaskIcon
                    icon={PhotoIcon}
                    count='5'
                />
            </FooterLeft>

            <FooterRight>
                {avatars.map(avatar =>
                    <Avatar key={avatar}>{avatar}</Avatar>
                )}
            </FooterRight>
        </FooterWrapper>
    );
};

export default PlateFooter;