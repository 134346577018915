import * as React from "react"

const Calendar = ({color, opacity, width, height, ...props}) => (
    <svg
        viewBox="0 0 22 21"
        width={width || 22}
        height={height || 21}
        fill="none"
        {...props}
    >
        <path
            d="M19 7H3m2-5V0m12 2V0M3 20h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2Z"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth={2}
        />
    </svg>
)

export default Calendar
