import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Chip from "../../../components/UI/Chip";
import {useDispatch} from "react-redux";
import getRandomInt from "../../../utils/getRandomInt";
import {COLUMN_HEADER_COLORS} from "../../../config/consts";
import {
    addColumn,
    addPlateToUnassignedColumn
} from "../../../reducers/projects/columnsSlice";
import {
    addPlate,
    searchPlates
} from "../../../reducers/projects/platesSlice";
import {openDialog} from "../../../reducers/root/dialogSlice";
import Input from "../../../components/UI/Input";
import PlusIcon from "../../../assets/icons/PlusIcon";

const TopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const ChipWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const TopBar = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        dispatch(searchPlates(searchText));
    }, [searchText]);

    function chipClick(chipId) {
        if (chipId === "chip-project") {
            dispatch(openDialog({
                id: "AddProjectDialog",
                props: {
                    dialogAgree: projectAddDialogAgree
                }
            }));
        }

        if (chipId === "chip-employee") {
            dispatch(openDialog({
                id: "AddEmployeeDialog",
                props: {
                    dialogAgree: employeeAddDialogAgree
                }
            }));
        }
    }

    function projectAddDialogAgree(columnTitle) {
        const randomIndex = getRandomInt(0, 5);
        const columnColor = COLUMN_HEADER_COLORS[randomIndex];
        dispatch(addColumn({columnTitle, columnColor}));
    }

    function employeeAddDialogAgree(employeeName) {
        const employeeId = "plate-" + Date.now();
        dispatch(addPlate({employeeName, plateId: employeeId}));
        dispatch(addPlateToUnassignedColumn({plateId: employeeId}));
    }

    return (
        <>
            <TopBarWrapper>
                <ChipWrapper>
                    <Chip
                        id="chip-project"
                        icon={PlusIcon}
                        label="Проект"
                        onClick={chipClick}
                    />
                </ChipWrapper>

                <ChipWrapper>
                    <Chip
                        id="chip-employee"
                        icon={PlusIcon}
                        label="Сотрудник"
                        onClick={chipClick}
                    />
                </ChipWrapper>

                <Input
                    placeholder='Поиск сотрудников'
                    value={searchText}
                    onChange={value => setSearchText(value)}
                />
            </TopBarWrapper>
        </>
    );
};

export default TopBar;