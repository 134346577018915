import {createSlice} from "@reduxjs/toolkit";
import {WORKLOAD_PLATES_DEFAULT} from "../../config/consts";

const plates = WORKLOAD_PLATES_DEFAULT;

const workloadPlatesSlice = createSlice({
    name: "workloadPlates",

    initialState: {
        plates
    },

    reducers: {
        addPlate(state) {
        },

        removePlate(state) {
        },

        addPlatesList(state) {
        }
    }
});

export default workloadPlatesSlice.reducer;

export const {
    addPlate,
    removePlate,
    addPlatesList
} = workloadPlatesSlice.actions;