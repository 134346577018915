import React from 'react';
import styled from "styled-components";

const ContentWrapper = styled.div`
  margin: 20px 0 25px 0;
`;

const DialogContent = (props) => {
    const {
        children
    } = props;

    return (
        <ContentWrapper>
            {children}
        </ContentWrapper>
    );
};

export default DialogContent;