import * as React from "react"
import theme from "../../config/theme";
import styled from "styled-components";


const MoreWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const MoreIcon = ({color, width, height, ...props}) => (
    <MoreWrapper>
        <svg
            width={width || 13}
            height={height || 3}
            fill="none"
            {...props}
        >
            <rect
                width={2.889}
                height={2.889}
                rx={1.444}
                fill={color || theme.colors.grey}
                fillOpacity={0.5}
            />
            <rect
                x={5.056}
                width={2.889}
                height={2.889}
                rx={1.444}
                fill={color || theme.colors.grey}
                fillOpacity={0.5}
            />
            <rect
                x={10.111}
                width={2.889}
                height={2.889}
                rx={1.444}
                fill={color || theme.colors.grey}
                fillOpacity={0.5}
            />
        </svg>
    </MoreWrapper>
)

export default MoreIcon
