import * as React from "react"
import styled from "styled-components";
import theme from "../../config/theme";


const ChatBubbleWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const ChatBubble = ({color, width, height, ...props}) => (
    <ChatBubbleWrapper>
        <svg
            viewBox="0 0 15 14"
            width={width || 15}
            height={height || 14}
            fill="none"
            {...props}
        >
            <path
                d="M13.167.333H1.833c-.779 0-1.416.6-1.416 1.334v12L3.25 11h9.917c.779 0 1.416-.6 1.416-1.333v-8c0-.734-.637-1.334-1.416-1.334Z"
                fill={color || theme.colors.grey}
                fillOpacity={0.5}
            />
        </svg>
    </ChatBubbleWrapper>
)

export default ChatBubble
